<template>
  <div class="main">
    <van-nav-bar class="top-bar has-bg-bar" title="活动详情" left-arrow @click-left="onClickLeft"/>
    <div class="detail-container">
      <div class="detail-title">{{ detail.title }}</div>
      <div class="detail-time">{{ detail.create_time }}</div>
      <div class="detail-content" v-html="detail.content" ref="contentRef"></div>
    </div>
    <van-popup
        v-model:show="fullImgVisible"
        closeable
        position="bottom"
        :style="{ height: '100%' }"
    >
      <div class="showFullContent">
        <img :src="fullImgSrc">
      </div>
    </van-popup>
    <!--底部按钮-->
    <div class="bottom-bar">
      <van-button type="primary" :disabled="!detail.game_name || !params.activity_id" round :to="'/personal/activity-rebate/'+params.activity_id +`/${detail.game_name}`">申请返利</van-button>
    </div>
  </div>
</template>

<script>
import {Button, NavBar, Popup} from "vant";
export default {
  name: "ActivityDetail",
  components: {
    [NavBar.name]: NavBar,
    [Popup.name]: Popup,
    [Button.name]: Button,
  }
}
</script>
<script setup>
import {nextTick, onMounted, reactive, ref} from "vue";
import {activityDetail} from "@/api/game";
import {date,onClickLeft} from "@/utils/function";
import {useRoute} from "vue-router";
import "@/style/common.css"

const detail = reactive({
  title: '',
  content: '',
  create_time: 0,
  game_name: '',
})
let contentRef = ref()
let fullImgVisible = ref(false)
let fullImgSrc = ref('')
const {params} = useRoute();
onMounted(()=>{
  activityDetail(params.activity_id).then(({data}) => {
    detail.title = data.title
    detail.create_time = date(data.create_time, 'Y-m-d H:i:s')
    detail.content = data.content
    detail.game_name = data.game_name
    nextTick(()=>{
      let imgNodes = contentRef.value.getElementsByTagName('img')
      for (let i = 0; i < imgNodes.length; i++) {
        imgNodes[i].addEventListener('click', function(event) {
          fullImgVisible.value = true
          fullImgSrc.value = imgNodes[i].src
        });
      }
    })
  })
})
</script>

<style scoped>
.main{
  padding-bottom: 80px;
}
.top-bar :deep(.van-nav-bar__content) {
  background: url("../../../assets/images/personal/msg-bar.png") bottom center no-repeat;
  background-size: 100% auto;
}
.top-bar::after{
  display: none;
}
.detail-container {
  padding: 12px 16px;
  text-align: left;
}

.detail-title {
  font-size: 22px;
  font-weight: 500;
  color: #1D2129;
  line-height: 30px;
}

.detail-time {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #86909C;
  line-height: 12px;
}

.detail-content {
  width:100%;
  overflow:hidden;
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  line-height: 22px;
  padding: 12px 0px;
}
.showFullContent{
  width:100%;
  overflow: auto;
}
.showFullContent img{
  max-width: unset;
}
.bottom-bar .van-button {
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 17px;
}
</style>
